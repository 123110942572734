<ng-container *ngIf="!itemSelected() && topicsBySubject()">
	<div
		class="menu-container"
		[ngClass]="{
			'overflow-scroll-gradient': scroll
		}"
	>
		<div
			class="menu-content menu-content-subjects overflow-scroll-gradient__scroller"
			#scrollContainer
			(scroll)="onResize()"
		>
			<div class="container-logo mobile" *ngIf="!mobileService.mainMenuVisited">
				<img src="./../../../assets/img/logo-circles.svg" alt="logo" class="logo" />
				<div class="text-group">
					<span class="title">{{ 'HOME.TITLE' | translate }}</span>
				</div>
			</div>
			<header class="menu-header">
				<h2 *ngIf="!mobile || mobileService.mainMenuVisited" class="menu-title">
					{{ 'MENU.MAIN_MENU_TITLE' | translate }}
				</h2>
				<h2 *ngIf="!mobileService.mainMenuVisited" class="menu-title mobile">Elige un tema para continuar</h2>
			</header>

			<unir-ai-menu-button
				[topicsBySubject]="topicsBySubject()"
				[(topicsSelected)]="topicsSelected"
				(topicSelected)="redirectToSingleTopic($event)"
			>
				<p-button
					*ngIf="topicsSelected.length"
					[disabled]="!topicsSelected.length"
					(onClick)="goToChatPage()"
					[outlined]="true"
					styleClass="flex justify-content-between"
					><span class="content">{{ 'MENU.SELECT_THEME' | translate }}</span
					><i class="icon unir-icon-arrow-right"></i
				></p-button>
				<p-button (onClick)="goToChatPage(true)" [outlined]="true" styleClass="flex justify-content-between"
					><span class="content">Toda la asignatura</span><i class="icon unir-icon-arrow-right"></i></p-button
			></unir-ai-menu-button>
			<!-- On button click, redirects to single topic, on checkbox, redirect to various topics -->
		</div>
	</div>
</ng-container>

<div
	class="menu-container"
	[ngClass]="{
		'overflow-scroll-gradient': scroll
	}"*ngIf="topicsInfo() && itemSelected()"
	
>
	<div #scrollContainer (scroll)="onResize()" class="menu-content overflow-scroll-gradient__scroller">
		<header class="menu-header mobile">
			<h2 class="menu-title menu-topics-title">{{ 'MENU.MAIN_MENU_TITLE' | translate }}</h2>
			<ng-icon (click)="this.menuService.closeMainMenu()" name="phosphorX" color="var(--main-menu-cross-color)" class="cross" />
		</header>
		<p-button (onClick)="goBack()">
			<span class="content text-left text-overflow-ellipsis"
				><i class="unir-icon-arrow-left"></i><span class="content">{{ topicsInfo()![0].subjectName }}</span></span
			></p-button
		>
		<ng-container *ngFor="let topic of topicsInfo()">
			<p-button
				styleClass="button-placeholder"
				class="button-placeholder highlight"
				[ngClass]="{ 'ml-2': topicsInfo()!.length > 1 }"
			>
				<span class="content text-left text-overflow-ellipsis">{{ topic.name }}</span>
			</p-button>
		</ng-container>

		<p-button [outlined]="true" (onClick)="openNewChat()"
			><span class="content text-left text-overflow-ellipsis"
				><i class="unir-icon-plus icon"></i>{{ 'MENU.NEW_CHAT' | translate }}</span
			>
		</p-button>
		<h3 class="chat-title" *ngIf="chatList?.chats?.length">Chats</h3>
		<div class="chats">
			<ng-container *ngFor="let chat of chatList?.chats">
				<unir-ai-chat-card
					(deleteChat)="removeChat($event)"
					(chatSelected)="openChat($event)"
					(newName)="renameChat($event)"
					[chat]="chat"
					[currentChatId]="currentChatId"
				></unir-ai-chat-card>
			</ng-container>
		</div>
	</div>
</div>
<div class="icon-container">
	<unir-ai-switcher-light-dark class="unir-switcher-light-dark"/>
	<img class="unir-icon" src="../../../../assets/img/unir-icon.svg" alt="" />
</div>

<p-toast></p-toast>
