<div class="main-container">
	<div [ngClass]="{ highlight: chat.chatId === currentChatId }" class="chat-card" (click)="openChat()">
		<input
			#input
			[disabled]="!edit()"
			[(ngModel)]="title"
			type="text"
			maxlength="25"
			(focusout)="onNameChange()"
			(keydown.enter)="onNameChange()"
		/>

		<span class="show-hover date">{{ chat.createdAt | formatDate }}</span>
		<!-- This button will overlap the input when is not editable -->
		<button (click)="openChat()" class="overlap-btn" [ngClass]="{ hidden: edit() }"></button>
	</div>
	<i  (click)="showDialog = !showDialog" class="icon show-hover unir-icon-dots-three"></i>
</div>

<div (mouseleave)="showDialog = false" class="dialog" *ngIf="showDialog">
	<a (click)="renameChat()"><i class="icon-submenu unir-icon-pencil-simple"></i>{{ 'CHAT.RENAME' | translate }}</a>
	<a class="danger" (click)="removeChat()"
		><i class="icon-submenu unir-icon-trash"></i>{{ 'CHAT.DELETE' | translate }}</a
	>
</div>
