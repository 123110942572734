import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter } from 'rxjs';

/**
 * Service to handle when the app is on mobile
 */
@Injectable({
	providedIn: 'root'
})
export class MobileService {
	mainMenuVisited = false;

	private mobileView = new BehaviorSubject<boolean | null>(null);

	display(value: boolean) {
		this.mobileView.next(value);
	}

	get $mobileView() {
		return this.mobileView.asObservable().pipe(
			distinctUntilChanged(),
			filter((value) => value !== null)
		);
	}
}
