import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { TopicsBySubjectResponse } from '../../../models/api/topics.model';
import { FormsModule } from '@angular/forms';
import { collapseAnimation } from 'angular-animations';

@Component({
	selector: 'unir-ai-menu-button',
	standalone: true,
	imports: [CommonModule, ButtonModule, CheckboxModule, FormsModule],
	templateUrl: './menu-button.component.html',
	styleUrls: ['./menu-button.component.scss'],
	animations: [collapseAnimation()]
})
export class MenuButtonComponent {
	@Input() topicsBySubject: TopicsBySubjectResponse | undefined;
	@Input() topicsSelected: number[] = [];
	@Output() topicsSelectedChange: EventEmitter<number[]> = new EventEmitter<number[]>();
	@Output() topicSelected: EventEmitter<number> = new EventEmitter<number>();
	protected collapse = signal(false);

	toggleCollapse() {
		this.collapse.update(value => !value);
	}

	onModelChange() {
		this.topicsSelectedChange.emit(this.topicsSelected);
	}
}
