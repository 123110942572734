<p-button
	(onClick)="toggleCollapse()"
	styleClass="mb-3"
	[icon]="collapse() ? 'unir-icon-caret-down' : 'unir-icon-caret-up'"
	[ngClass]="{ highlight: !collapse() }"
>
	<span class="content text-left text-overflow-ellipsis">
		<i class="unir-icon-book-open icon"></i>{{ topicsBySubject?.subjectName }}</span
	></p-button
>
<div [@collapse]="collapse()" class="topics">
	<ng-container *ngFor="let topic of topicsBySubject?.topics; let i = index">
		<div [ngClass]="{ active: topicsSelected.length }" class="check-btn">
			<p-checkbox
				class="check"
				[value]="topic.id"
				[(ngModel)]="topicsSelected"
				(ngModelChange)="onModelChange()"
			></p-checkbox>
			<p-button class="menu-button w-full" (onClick)="topicSelected.emit(topic.id)"
				><span class="content content-topics">
					<p>{{ topic.name }}</p></span
				></p-button
			>
		</div>
	</ng-container>
  <div class="ng-content">
    <ng-content></ng-content>
  </div>

</div>
