import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LoadingService {
	public status: Subject<boolean> = new Subject<boolean>();

  loading = false;

	display(value: boolean) {
		this.status.next(value);
	}
}
