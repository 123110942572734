import { Injectable, inject } from '@angular/core';
import { APIURL } from '../../../app';
import { HttpClient } from '@angular/common/http';
import { Observable, of, take, tap } from 'rxjs';
import {
	CaseStudyResponseCreation,
	ChatCreationResponse,
	ChatDataResponse,
	FormulaResponseCreation,
	SummaryResponseCreation,
	TermResponseCreation,
	TestCreationId,
	ChatList,
	ChatPost,
	AllowedActionsResponse 
} from '../models/api/chats.model';
@Injectable({
	providedIn: 'root'
})
export class ChatService {
	apiUrl = APIURL;

	httpClient = inject(HttpClient);

	chatIdForsService?: number;

	getChats(topicIDs?: number[]) {
		let query = '/';
		if (topicIDs?.length) {
			let firstExecution = true;
			topicIDs.forEach((id) => {
				firstExecution ? (query = query.concat(`?TopicIds=${id}`)) : (query = query.concat(`&TopicIds=${id}`));
				firstExecution = false;
			});
		} else {
			query = '';
		}
		return this.httpClient.get<ChatList>(`${this.apiUrl}/chats${query}`);
	}

	getChatById(id: number, page: number, pageSize: number) {
		return this.httpClient.get<ChatDataResponse>(`${this.apiUrl}/chats/${id}?page=${page}&pageSize=${pageSize}`);
	}

	addChat(chat: ChatPost) {
		return this.httpClient.post(`${this.apiUrl}/chats`, { chat });
	}

	createChat(name: string, topics: number[]) {
		return this.httpClient.post<ChatCreationResponse>(`${this.apiUrl}/chats`, { name, topics });
	}

	removeChat(chatId: number) {
		return this.httpClient.delete(`${this.apiUrl}/chats/${chatId}`);
	}

	renameChat(chatId: number, name: string) {
		const headers = { 'Content-Type': 'application/json' };
		return this.httpClient.patch(`${this.apiUrl}/chats/${chatId}`, { name: name }, { headers: headers });
	}

	createTest(chatId: number, numberOfQuestions: number) {
		return this.httpClient.post<TestCreationId>(`${this.apiUrl}/chats/${chatId}/test`, {
			questionAmount: numberOfQuestions
		});
	}

	getTest(chatId: number) {
		return this.httpClient.get<TestCreationId>(`${this.apiUrl}/chats/${chatId}/tests`);
	}

	createSummary(chatId: number) {
		return this.httpClient.get<SummaryResponseCreation>(`${this.apiUrl}/chats/${chatId}/summaries`);
	}

	createFormula(chatId: number) {
		return this.httpClient.get<FormulaResponseCreation>(`${this.apiUrl}/chats/${chatId}/formulas`);
	}
	createTerm(chatId: number) {
		return this.httpClient.get<TermResponseCreation>(`${this.apiUrl}/chats/${chatId}/terms`);
	}

	getAllowedActions(subjectId: number) {
		const cacheKey = 'allowedActions' + subjectId;

		const observable = this.httpClient
			.get<AllowedActionsResponse>(`${this.apiUrl}/chats/${subjectId}/cards`)
			.pipe(tap((res) => sessionStorage.setItem(cacheKey, JSON.stringify(res))));

		return this.returnCacheObservable(cacheKey, observable);
	}

	createCaseStudy(chatId: number) {
		return this.httpClient.get<CaseStudyResponseCreation>(`${this.apiUrl}/chats/${chatId}/case-study`);
	}

	private returnCacheObservable<T>(cacheKey: string, fallbackObservable: Observable<T>): Observable<T> {
		const cachedResponse = sessionStorage.getItem(cacheKey);

		if (cachedResponse) {
			try {
				return of(JSON.parse(cachedResponse)).pipe(take(1));
			} catch (error) {
				sessionStorage.removeItem(cacheKey);
			}
		}
		return fallbackObservable.pipe(tap((res) => sessionStorage.setItem(cacheKey, JSON.stringify(res))));
	}
}