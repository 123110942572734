import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'unir-ai-loading',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {}
