import { Route } from '@angular/router';

export enum appRoutesType {
	CHAT = 'chat',
	TEST = 'test',
	HOME = 'home',
	VERSION = 'version',
	PRACTICAL_CASES = 'practical-cases'
}

export const appRoutes: Route[] = [
	{
		path: appRoutesType.HOME,
		loadComponent: () => import('./pages/home/home.component').then((m) => m.HomePageComponent)
	},
	{
		path: `${appRoutesType.CHAT}`,
		loadComponent: () => import('./pages/chat/chat.component').then((m) => m.ChatPageComponent),
		children: [
			{ path: ':id', loadComponent: () => import('./pages/chat/chat.component').then((m) => m.ChatPageComponent) },
			{ path: '', loadComponent: () => import('./pages/chat/chat.component').then((m) => m.ChatPageComponent) }
		]
	},
	{
		path: `${appRoutesType.TEST}/:id`,
		loadComponent: () => import('./pages/test/test.component').then((m) => m.TestPageComponent)
	},
	{
		path: `${appRoutesType.VERSION}`,
		loadComponent: () => import('./pages/version/version.component').then((m) => m.VersionComponent)
	},
	{
		path: `${appRoutesType.PRACTICAL_CASES}/:id`,
		loadComponent: () =>
			import('./pages/practical-cases/practical-cases.page.component').then((m) => m.PracticalCasesPageComponent)
	},
	{
		path: '',
		redirectTo: appRoutesType.HOME,
		pathMatch: 'full'
	},
  	{ path: '**', redirectTo: appRoutesType.HOME }
];
