import { Configuration, version } from './configuration';
/* import { setupWorker } from 'msw/browser';
import { spaConfigHandlers } from '../app/mocks/handlers';

const onBootstrapAppInit = async () => {
    // Mock implementation of the API in development mode
    const preparadorWorker = setupWorker(...spaConfigHandlers);
    await preparadorWorker.start({ onUnhandledRequest: 'bypass' });
}; */

export const env: Configuration = {
	url: 'https://app-bff-preparador-dev-we-001.azurewebsites.net',
	production: false,
	version,
    //onBootstrapAppInit
};
