import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { MenuAction, MenuActionType } from '../models/app/menu-action.model';

/**
 * Service to handle the menu actions
 * Menu actions are handled via one observable
 * There is a enum that defines the actions
 * Components will listen and filter base on the enum
 */
@Injectable({
	providedIn: 'root'
})
export class MenuService {
	topicsMenuOpen = false;

	private _action = new Subject<MenuAction>();

	private _menuClosed = new BehaviorSubject(false);
	editAction(value: MenuAction) {
		this._action.next(value);
	}

	menuClosedNext(value: boolean) {
		this._menuClosed.next(value);
	}

	get menuClosed$() {
		return this._menuClosed.asObservable();
	}

	get action$(): Observable<MenuAction> {
		return this._action.asObservable();
	}

	closeMainMenu() {
		this.editAction({ action: MenuActionType.CLOSE_MENU });
	}
}
