import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule, InputSwitchChangeEvent } from 'primeng/inputswitch';

@Component({
	selector: 'unir-ai-switcher-light-dark',
	standalone: true,
	imports: [CommonModule, FormsModule, InputSwitchModule],
	templateUrl: './switcher-light-dark.component.html',
	styleUrls: ['./switcher-light-dark.component.scss']
})
export class SwitcherLightDarkComponent implements OnInit {
	isDarkTheme = false;
	private renderer = inject(Renderer2);
	private document = inject(DOCUMENT);

	ngOnInit() {
		this.checkThemeMode();
	}

	changeThemeMode(e: InputSwitchChangeEvent) {
		this.updateThemeMode(e.checked);
	}

	/**
	 * Checks the theme mode based on the user's preference or system default.
	 */
	private checkThemeMode() {
		// Check the user's preference
		const localDarkTheme = localStorage.getItem('data-theme');

		// If the user has no preference, check the system default for dark theme
		!localDarkTheme
			? (this.isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches)
			: (this.isDarkTheme = localStorage.getItem('data-theme') === 'dark' ? true : false);

		this.updateThemeMode(this.isDarkTheme);
	}

	private updateThemeMode(isDarkTheme: boolean) {
		if (isDarkTheme) {
			localStorage.setItem('data-theme', 'dark');
			this.renderer.setAttribute(this.document.body, 'data-theme', 'dark');
		} else {
			localStorage.setItem('data-theme', 'light');
			this.renderer.removeAttribute(this.document.body, 'data-theme');
		}
	}
}
